import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospitalUser,
  faNotesMedical,
  faUser,
  faFileMedical,
  faClipboardUser,
  // faLanguage,
  faPersonBreastfeeding,
} from "@fortawesome/free-solid-svg-icons";

interface CVProps {
  formData: {
    firstName: string;
    lastName: string;
    age: string;
    englishLevel: string;
    address: {
      city: string;
      postalCode: string;
      country: string;
    };
    profile: string;
    profilePicture: string;
    skills: {
      skill: string;
      selected: boolean;
    }[];
    languages: string[];
    drivingLicense: boolean;
    availableDate: string;
    bio: string;
    education: {
      school: string;
      diploma: string;
      field: string;
      startDate: string;
      endDate: string;
      description: string;
    }[];
    experienceYears: string;
    // hasChildren: boolean;
    // childrenDetails: string;
    hasPetAllergies: boolean;
    petAllergyDetails: string;
    experiences: {
      jobTitle: string;
      employmentType: string;
      company: string;
      startDate: string;
      endDate: string;
      description: string;
    }[];
  };
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Autres"; // Retourne "Autres" si la date n'est pas valide
  }
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const CandidateCV: React.FC<CVProps> = ({ formData }) => {
  const selectedSkills = formData.skills.filter((skill) => skill.selected);
  // const currentDate = new Date();
  // const formattedCurrentDate = formatDate(currentDate.toISOString());

  return (
    <div className="cv-container">
      {/* Header Section */}
      <div className="cv-header">
        <img
          src={`${process.env.PUBLIC_URL}/nanny.png`}
          alt="Icon"
          className="cv-icon-nanny"
        />
        {formData.profilePicture && (
          <div className="cv-photo-container">
            <img
              src={formData.profilePicture}
              alt="Profile"
              className="cv-profile-picture"
            />
          </div>
        )}
        <div className="cv-name-job">
          <h1>
            {formData.firstName} {formData.lastName}
          </h1>
        </div>
      </div>
      <div className="cv-name-job"></div>

      {/* Contact Section */}
      <div className="cv-contact-info">
        <div className="title-container flex items-center">
          <FontAwesomeIcon icon={faUser} className="text-gold mx-2" />
          <h3>INFORMATIONS PERSONNELLES</h3>
        </div>
        <div className="cv-contact-item">
          {formData.address.city &&
            formData.address.postalCode &&
            formData.address.country && (
              <p>
                <strong>Adresse:</strong> {formData.address.city},{" "}
                {formData.address.postalCode}, {formData.address.country}
              </p>
            )}
          {formData.age && (
            <p>
              <strong>Age:</strong> {formData.age}
            </p>
          )}
          {formData.englishLevel && (
            <p>
              <strong>Niveau d'anglais:</strong> {formData.englishLevel}
            </p>
          )}
          {formData.drivingLicense && (
            <p>
              <strong>Permis de conduire:</strong>{" "}
              {formData.drivingLicense ? "Oui" : "Non"}
            </p>
          )}
          {formData.availableDate && (
            <p>
              <strong>Date de disponibilité:</strong> {formData.availableDate}
            </p>
          )}
          {formData.languages.length > 0 && (
            <p>
              <strong>Autres langues parlées:</strong>{" "}
              {formData.languages.join(", ")}
            </p>
          )}
        </div>
      </div>

      {/* Profile Section */}
      {formData.bio && (
        <div className="cv-section">
          <div className="profile-header flex items-center">
            <FontAwesomeIcon icon={faClipboardUser} className="profil-icon" />
            <h3>Profil</h3>
          </div>
          <p>{formData.bio}</p>
        </div>
      )}

      {/* Education Section */}
      {formData.education.length > 0 && (
        <div className="cv-section">
          <div className="education-header flex items-center">
            <FontAwesomeIcon icon={faFileMedical} className="education-icon" />
            <h3>Formation</h3>
          </div>
          {formData.education.map((education, index) => (
            <div key={index} className="education-item">
              {(education.school || education.diploma || education.field) && (
                <p>
                  <strong>{education.school}</strong> {education.diploma}{" - "}
                  {education.field}
                </p>
              )}
              {(education.startDate || education.endDate) && (
                <p>
                  {formatDate(education.startDate)} -{" "}
                  {formatDate(education.endDate)}
                </p>
              )}
              {education.description && <p>{education.description}</p>}
            </div>
          ))}
        </div>
      )}

      {/* Experience Section */}
      {formData.experiences.length > 0 && (
        <div className="cv-section">
          <div className="experience-header flex items-center">
            <FontAwesomeIcon
              icon={faPersonBreastfeeding}
              className="experience-icon"
            />
            <h3>Expériences professionnelles</h3>
          </div>
          {formData.experiences.map((experience, index) => (
            <div key={index} className="experience-item">
              {(experience.jobTitle ||
                experience.company ||
                experience.employmentType) && (
                <p>
                  <strong>{experience.jobTitle}</strong> {experience.company} (
                  {experience.employmentType})
                </p>
              )}
              {(experience.startDate || experience.endDate) && (
                <p>
                  {formatDate(experience.startDate)} -{" "}
                  {formatDate(experience.endDate)}
                </p>
              )}
              {experience.description && <p>{experience.description}</p>}
            </div>
          ))}
        </div>
      )}

      {/* Questions Section */}
      <div className="cv-section">
        <div className="questions-header flex items-center">
          <FontAwesomeIcon icon={faHospitalUser} className="questions-icon" />
          <h3>Questions</h3>
        </div>
        {formData.experienceYears && (
          <p>
            <strong>
              Combien d’années d’expériences avez-vous ?
            </strong>{" "}
            {formData.experienceYears}
          </p>
        )}
        {/* {formData.hasChildren !== undefined && (
          <>
            <p>
              <strong>Avez-vous des enfants ?</strong>{" "}
              {formData.hasChildren ? "Oui" : "Non"}
            </p>
            {formData.hasChildren && formData.childrenDetails && (
              <p>
                <strong>Détails sur les enfants:</strong>{" "}
                {formData.childrenDetails}
              </p>
            )}
          </>
        )} */}
        {formData.hasPetAllergies !== undefined && (
          <>
            <p>
              <strong>Avez-vous des allergies aux animaux ?</strong>{" "}
              {formData.hasPetAllergies ? "Oui" : "Non"}
            </p>
            {formData.hasPetAllergies && formData.petAllergyDetails && (
              <p>
                <strong>Détails des allergies:</strong>{" "}
                {formData.petAllergyDetails}
              </p>
            )}
          </>
        )}
      </div>

      {/* Skills Section */}
      {selectedSkills.length > 0 && (
        <div className="cv-section">
          <div className="skills-header flex items-center">
            <FontAwesomeIcon icon={faNotesMedical} className="Skills" />
            <h3>Compétences</h3>
          </div>
          <ul>
            {selectedSkills.map((skill, index) => (
              <li key={index}>{skill.skill}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CandidateCV;
